<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="brands-create" v-else>
      <span class="tw-text-xl tw-font-semibold tw-ml-6">{{ $t('brands.create.title') }}</span>
      <v-stepper class="brands-create__stepper" elevation="0" v-model="currentStep">
        <v-stepper-header class="brands-create__stepper__header">
          <v-stepper-step :complete="currentStep > 1" step="1">
            {{ $t('brands.create.step.first.label') }}
          </v-stepper-step>
          <v-divider class="tw-mx-4" />
          <v-stepper-step :complete="currentStep > 2" step="2">
            {{ $t('brands.create.step.second.label') }}
          </v-stepper-step>
          <v-divider class="tw-mx-4" />
          <v-stepper-step :complete="currentStep > 3" step="3">
            {{ $t('brands.create.step.third.label') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="brands-create__stepper__content">
          <form class="tw-flex tw-flex-col tw-justify-center" @submit.prevent="onSubmit" novalidate autocomplete="off">
            <v-stepper-content class="sm:tw-w-2/3 tw-mx-auto" step="1">
              <span class="tw-font-semibold">{{ $t('brands.create.step.first.title') }}</span>
              <div class="brands-create__stepper__content__grid">
                <ui-block id="nameLabel" class="required-asterisk" :label="$t('brands.create.form.name.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.name"
                      id="name"
                      solo
                      flat
                      outlined
                      dense
                      :placeholder="$t('brands.create.form.name.placeholder')"
                      :error-messages="simpleErrors('brandModel', 'name')"
                    />
                  </template>
                </ui-block>
                <ui-block id="clientLabel" class="required-asterisk" :label="$t('brands.create.form.client.label')">
                  <template v-slot:body>
                    <v-text-field
                      :value="currentClient.name"
                      id="client"
                      solo
                      flat
                      disabled
                      readonly
                      dense
                      :placeholder="$t('brands.create.form.client.placeholder')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="defaultLocaleIsoCodeLabel"
                  class="required-asterisk"
                  :label="$t('brands.create.form.defaultLocaleIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="brandModel.defaultLocaleIsoCode"
                      id="defaultLocaleIsoCode"
                      solo
                      flat
                      outlined
                      dense
                      item-value="code"
                      item-text="name"
                      :items="locales"
                      :placeholder="$t('brands.create.form.defaultLocaleIsoCode.placeholder')"
                      :error-messages="simpleErrors('brandModel', 'defaultLocaleIsoCode')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="defaultCountryIsoCodeLabel"
                  class="required-asterisk"
                  :label="$t('brands.create.form.defaultCountryIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="brandModel.defaultCountryIsoCode"
                      id="defaultCountryIsoCode"
                      solo
                      flat
                      outlined
                      dense
                      item-value="0"
                      item-text="1"
                      :items="Object.entries(availablesCountries)"
                      :placeholder="$t('brands.create.form.defaultCountryIsoCode.placeholder')"
                      :error-messages="simpleErrors('brandModel', 'defaultCountryIsoCode')"
                    />
                  </template>
                </ui-block>
                <ui-block id="typeLabel" class="required-asterisk" :label="$t('brands.create.form.type.label')">
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="brandModel.type"
                      id="type"
                      solo
                      flat
                      outlined
                      dense
                      item-value="identifier"
                      item-text="name"
                      :items="types"
                      :placeholder="$t('brands.create.form.type.placeholder')"
                      :error-messages="simpleErrors('brandModel', 'type')"
                    />
                  </template>
                </ui-block>
              </div>
              <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                <v-btn :to="{ name: 'BrandsList' }" outlined class="tw-px-8" color="#c5c5c5">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn @click="checkIsClientValid" class="tw-px-8" color="#449afd" dark>
                  {{ $t('button.continue') }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content class="tw-w-2/3 tw-mx-auto" step="2">
              <span class="tw-font-semibold">{{ $t('brands.create.step.second.title') }}</span>
              <div class="tw-grid tw-grid-cols-2 tw-gap-x-12 tw-mt-4">
                <ui-block id="brandColorLabel" :label="$t('brands.create.form.brandColor.label')">
                  <template v-slot:body>
                    <ui-color-picker
                      :id="'brand-color'"
                      :current-color="brandModel.customFields.identity.brandColor"
                      :placeholder="$t('brands.create.form.brandColor.placeholder')"
                      @colorUpdate="color => (brandModel.customFields.identity.brandColor = color)"
                    />
                  </template>
                </ui-block>
                <ui-block id="brandAccentLabel" :label="$t('brands.create.form.brandAccent.label')">
                  <template v-slot:body>
                    <ui-color-picker
                      :id="'brand-accent'"
                      :current-color="brandModel.customFields.identity.brandAccent"
                      :placeholder="$t('brands.create.form.brandAccent.placeholder')"
                      @colorUpdate="color => (brandModel.customFields.identity.brandAccent = color)"
                    />
                  </template>
                </ui-block>
                <ui-block id="brandLightenLabel" :label="$t('brands.create.form.brandLighten.label')">
                  <template v-slot:body>
                    <ui-color-picker
                      :id="'brand-lighten'"
                      :current-color="brandModel.customFields.identity.brandLighten"
                      :placeholder="$t('brands.create.form.brandLighten.placeholder')"
                      @colorUpdate="color => (brandModel.customFields.identity.brandLighten = color)"
                    />
                  </template>
                </ui-block>
                <ui-block id="brandDarkenLabel" :label="$t('brands.create.form.brandDarken.label')">
                  <template v-slot:body>
                    <ui-color-picker
                      :id="'brand-darken'"
                      :current-color="brandModel.customFields.identity.brandDarken"
                      :placeholder="$t('brands.create.form.brandDarken.placeholder')"
                      @colorUpdate="color => (brandModel.customFields.identity.brandDarken = color)"
                    />
                  </template>
                </ui-block>
              </div>
              <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                <v-btn @click="currentStep -= 1" outlined class="tw-px-8" color="#c5c5c5">
                  {{ $t('button.prev') }}
                </v-btn>
                <v-btn @click="currentStep += 1" class="tw-px-8" color="#449afd" dark>
                  {{ $t('button.continue') }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content class="tw-w-2/3 tw-mx-auto" step="3">
              <span class="tw-font-semibold">{{ $t('brands.create.step.third.title') }}</span>
              <div class="brands-create__stepper__content__grid">
                <ui-block id="nameRecapLabel" :label="$t('brands.create.form.name.label')">
                  <template v-slot:body>
                    <v-text-field v-model="brandModel.name" id="nameRecap" solo outlined flat disabled readonly dense />
                  </template>
                </ui-block>
                <ui-block id="clientRecapLabel" :label="$t('brands.create.form.client.label')">
                  <template v-slot:body>
                    <v-text-field
                      :value="currentClient.name"
                      id="clientRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="defaultLocaleIsoCodeRecapLabel"
                  :label="$t('brands.create.form.defaultLocaleIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.defaultLocaleIsoCode"
                      id="defaultLocaleIsoCodeRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block
                  class="required-asterisk"
                  id="defaultCountryIsoCodeRecapLabel"
                  :label="$t('brands.create.form.defaultCountryIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.defaultCountryIsoCode"
                      id="defaultCountryIsoCodeRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block id="brandColorRecapLabel" :label="$t('brands.create.form.brandColor.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.customFields.identity.brandColor"
                      id="brandColorRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block id="brandAccentRecapLabel" :label="$t('brands.create.form.brandAccent.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.customFields.identity.brandAccent"
                      id="brandAccentRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block id="brandLightenRecapLabel" :label="$t('brands.create.form.brandLighten.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.customFields.identity.brandLighten"
                      id="brandLightenRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
                <ui-block id="brandDarkenRecapLabel" :label="$t('brands.create.form.brandDarken.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="brandModel.customFields.identity.brandDarken"
                      id="brandDarkenRecap"
                      solo
                      outlined
                      flat
                      disabled
                      readonly
                      dense
                    />
                  </template>
                </ui-block>
              </div>
              <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                <v-btn @click="currentStep -= 1" outlined class="tw-px-8" color="#c5c5c5">
                  {{ $t('button.prev') }}
                </v-btn>
                <v-btn :loading="saving" type="submit" class="tw-px-8" color="#449afd" dark>
                  {{ $t('button.save') }}
                </v-btn>
              </div>
            </v-stepper-content>
          </form>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ErrorsMixin from '@/mixins/errors.mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import UiColorPicker from '@/components/UI/ColorPicker.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'BrandsCreate',
  components: {
    UiBlock,
    UiColorPicker,
  },
  mixins: [validationMixin, ErrorsMixin],
  data() {
    return {
      saving: false,
      brandModel: {
        name: '',
        client: '',
        logoPng: '',
        logoSvg: '',
        favicon: '',
        defaultLocaleIsoCode: 'fr-fr',
        defaultCountryIsoCode: 'FR',
        type: null,
        customFields: {
          identity: {
            brandColor: '',
            brandAccent: '',
            brandLighten: '',
            brandDarken: '',
          },
        },
      },
      currentStep: 1,
    }
  },
  async created() {
    this.setUpdating(true)
    await this.getAuthentication()
    try {
      await Promise.all([this.getClientCore({ uuid: this.$route.params.id }), this.getTypes()])
      this.brandModel.client = `clients/${this.currentClient.uuid}`
      this.brandModel.type = this.types[0].identifier
    } catch (error) {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.client'),
      })
      this.$router.push({ name: 'ClientsList' })
    }
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      locales: state => state.backoffice.locales,
      updating: state => state.backoffice.updating,

      availablesCountries: state => state.gmb.availablesCountries,

      currentBrand: state => state.brand.currentBrand,

      types: state => state.setting.types,

      currentClient: state => state.client.currentClient,
    }),
  },
  methods: {
    ...mapActions({
      getClientCore: 'client/getClientCore',
      createBrand: 'brand/createBrand',
      getTypes: 'setting/getTypes',

      getAuthentication: 'backoffice/getAuthentication',
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
    }),
    async checkIsClientValid() {
      const errors = await this.fieldsHaveErrors('brandModel', [
        'name',
        'client',
        'defaultLocaleIsoCode',
        'defaultCountryIsoCode',
      ])
      if (!errors) {
        this.currentStep += 1
      }
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true

        try {
          await this.createBrand({ brand: this.brandModel })
          this.$router.push({ name: 'BrandsDashboard', params: { id: this.currentBrand.uuid } })
          this.showNotificationSuccess()
        } catch {
          this.showNotificationError()
        }
        this.saving = false
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.create.brand', { name: this.currentBrand.name }),
      })
      this.saving = false
    },
  },
  validations() {
    return {
      brandModel: {
        name: {
          required,
        },
        client: {
          required,
        },
        defaultLocaleIsoCode: {
          required,
        },
        defaultCountryIsoCode: {
          required,
        },
        type: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.brands-create {
  @apply tw-h-full tw-m-8;

  &__stepper {
    @apply tw-my-4;
    background-color: inherit !important;

    &__header {
      box-shadow: none !important;
    }

    &__content {
      @apply tw-mx-0 tw-mt-0 sm:tw-mt-6 sm:tw-mx-4;
      background-color: $white;

      &__grid {
        @apply tw-mx-auto tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full;
      }
    }
  }
}
</style>
