<template>
  <v-menu :close-on-content-click="false" transition="scale-transition" min-width="auto">
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="currentColor"
        id="brandColor"
        readonly
        solo
        flat
        outlined
        dense
        :placeholder="placeholder"
        v-on="on"
        :append-icon="disabledPicker ? icons.mdiCheckboxBlankOutline : icons.mdiCheckboxMarked"
        @click:append="updateRemoveColor"
      >
        <template v-slot:prepend-inner>
          <v-avatar :id="id" @click="on.click" tile size="24" :color="currentColor || '#cbd5e1'" />
        </template>
      </v-text-field>
    </template>
    <v-color-picker
      v-if="!disabledPicker"
      v-model="modelCurrentColor"
      :mode.sync="colorMode"
      hide-mode-switch
      dot-size="20"
    />
  </v-menu>
</template>

<script>
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js'

export default {
  name: 'UiColorPicker',
  props: {
    id: {
      type: String,
      required: true,
    },
    currentColor: {
      type: String,
      required: false,
      default: '',
    },
    colorMode: {
      type: String,
      required: false,
      default: 'hexa',
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
      },
      disabledPicker: false,
    }
  },
  created() {
    if (!this.currentColor) {
      this.disabledPicker = true
    }
  },
  computed: {
    modelCurrentColor: {
      get() {
        return this.currentColor
      },
      set(color) {
        this.$emit('colorUpdate', color)
      },
    },
  },
  methods: {
    async updateRemoveColor() {
      this.disabledPicker = !this.disabledPicker
      this.$emit('colorUpdate', '')
    },
  },
}
</script>
